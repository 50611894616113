
import './App.css';
import { useState, useEffect } from 'react';
import TiktokPixel from 'tiktok-pixel';
import ReactPixel from 'react-facebook-pixel';

function App() {
    const [domain] = useState(document.location.hostname);
    const [path] = useState(document.location.pathname);
    const [info, setInfo] = useState(null);
    const [pixelInit, setPixelInit] = useState(false);
    useEffect(() => {
        function getInfo() {
            if (info === null) {
                fetch(`https://dev.growve.tools:8080/redirectInfo/${domain}`, {
                    method: 'GET',
                    mode: 'cors'
                })
                    .then(response => {
                        return response.json()
                    })
                    .then(json => {
                        setInfo(json);
                    })
            }
        };
        getInfo();
    }, [domain, info]);

    useEffect(() => {
        function pixelTriggers() {
            if (info !== null && !pixelInit) {
                if (info.facebook !== '' && info.tiktok !== '') {
                    ReactPixel.init(info.facebook);
                    ReactPixel.pageView();
                    TiktokPixel.init(info.tiktok);
                } else if (info.facebook === '' && info.tiktok !== '') {
                    TiktokPixel.init(info.tiktok);
                } else if (info.facebook !== '' && info.tiktok === '') {
                    ReactPixel.init(info.facebook);
                    ReactPixel.pageView();
                } else {
                    return;
                }
                setPixelInit(true);
            }
        };

        pixelTriggers();
    }, [info, pixelInit]);

    useEffect(() => {
        function redirect() {
            if (info !== null && pixelInit) {
                window.open(info.link, '_self');
            }
        };
        redirect();
    }, [info, pixelInit]);
  return (
    <div className="App">
      
    </div>
  );
}

export default App;
